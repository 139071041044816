import React, { useState, useEffect } from "react";
import "./TournamentComponent.styles.css";
import TagAndChips from "components/common/TagAndChips";
import { Button } from "react-bootstrap";
import Table from "components/common/Table";
import { listSport } from "../../_actions/sports/sports.action";
import { listLeague } from "../../_actions/league/league.action";
import { connect } from "react-redux";
import Loader from "components/common/Loader";
import { getLeagueEventsList } from "_services";
import ReactSelect from "react-select";

function TournamentComponent(props) {
  const [ActiveTab, seActiveTab] = useState(true);
  const [ActiveTabName, seActiveTabName] = useState("main");
  const [sportList, setsportList] = useState([]);
  const [isSportLoading, setIsSportLoading] = useState(true);
  const [selectedSport, setSelectedSport] = useState(4);
  const [leagueList, setLeagueList] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [initialleagueList, setInitialLeagueList] = useState([]);

  useEffect(() => {
    handleSportChange(selectedSport);
    props.dispatch(
      listSport(null, (res) => {
        if (res && res.data) {
          setsportList(res.data);
          setIsSportLoading(false);
        }
      })
    );
  }, []);

  useEffect(() => {
    let dataOptions = leagueList.map((val) => val.league_name);
    if (!selectedOption?.value) {
      setOptions(dataOptions);
    }
  }, [leagueList]);

  useEffect(() => {
    if (selectedOption?.value) {
      let newList = initialleagueList.filter(
        (val) => val.league_name === selectedOption?.value
      );
      setLeagueList(newList);
    } else {
      setLeagueList(initialleagueList);
    }
  }, [selectedOption]);

  const handleChange = (event) => {
    setSelectedOption(event);
  };

  const handleSportChange = (sport_id) => {
    let sendObj = {
      sport_id,
    };
    props.dispatch(
      listLeague(sendObj, (res) => {
        if (res && res.data) {
          setLeagueList(res.data);
          setInitialLeagueList(res.data);
          setSelectedSport(sport_id);
        }
      })
    );
  };

  const handleLeagueEventsList = (league_id = "") => {
    if (league_id) {
      let sendData = {};
      sendData.league_id = league_id;
      getLeagueEventsList(sendData)
        .then((response) => {

          let league_list = JSON.parse(JSON.stringify(leagueList));
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            league_list.forEach((element) => {
              if (element.league_id === league_id) {
                element.subElements = response.data.data;
              }
            });
          } else {
            league_list.forEach((element) => {
              if (element.league_id === league_id) {
                element.subElements = response.data.data;
              }
            });
          }
          setLeagueList(league_list);
        })
        .catch((error) => { });
    }
  };

  const activeChips = (a, b) => {
    return a === b ? true : false;
  };

  const handleReset = () => {
    setSelectedOption("");

    setLeagueList(initialleagueList);

  }

  // console.log(options, "options")

  return (
    <div>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Schedule</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="">Dashboard</a>
            </li>

            <li className="breadcrumb-item active">
              <strong>Schedule</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="">
              <div className="ibox-content text-center pb-05">
                <div className="TabBar">
                  <div
                    className={ActiveTab ? "TabBarChildActive" : "TabBarChild"}
                    onClick={() => {
                      seActiveTab((prev) => !prev);
                      seActiveTabName("main");
                      setSelectedOption(null);
                    }}
                  >
                    Betfair
                  </div>
                  <div
                    className={!ActiveTab ? "TabBarChildActive" : "TabBarChild"}
                    onClick={() => {
                      seActiveTab((prev) => !prev);
                      seActiveTabName("fancy");
                      setSelectedOption(null);
                    }}
                  >
                    Cricket Session
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 p-1 ta-l d-flex gap-05 overflow-auto">
                    {isSportLoading ? (
                      <Loader />
                    ) : (
                      sportList &&
                      sportList.map((item) => (
                        <TagAndChips
                          key={item.sport_id}
                          onClick={() => handleSportChange(item.sport_id)}
                          className={
                            activeChips(selectedSport, item.sport_id)
                              ? "btn btn-primary2 btn-rounded"
                              : "btn btn-default btn-rounded"
                          }
                        >
                          {item.sports_name}
                        </TagAndChips>
                      ))
                    )}
                  </div>

                  <div className="col-lg-6 p-0 pt-1">
                    {/* <div className="form-group row "> */}
                    <div className="col-sm-12 d-flex gap-1">
                      <ReactSelect
                        isClearable={true}
                        styles={{
                          container: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                          }),
                          option: (provided) => ({
                            ...provided,
                            borderBottom: "1px dotted pink",
                            padding: 10,
                            justifyContent: "start",
                            display: "flex",
                          }),
                          input: (provided) => ({
                            ...provided,
                            width: "100%",
                            display: "flex",
                            justifyContent: "start",
                          }),
                        }}
                        name="account"
                        onChange={handleChange}
                        value={selectedOption}
                        options={
                          options?.length > 0
                            ? options?.map((item) => ({
                              value: item,
                              label: item,
                            }))
                            : []
                        }
                      />
                      <Button
                        className="btn btn-primary2"
                        onClick={() => handleReset()}
                      >
                        Reset
                      </Button>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                <Table
                  Headers={["S.NO", "LEAGUE ID", "LEAGUE NAME", "ACTION"]}
                  Data={leagueList}
                  handleSubTable={handleLeagueEventsList}
                  subTableMarketUrl={"/fixture-market"}
                  ActiveTabName={ActiveTabName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(TournamentComponent);
